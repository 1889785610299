<template>
  <div class="my-4">
    <div class="">
      <h4 class="">Rewards</h4>
      <ul
        class="nav border-bottom text-center d-flex justify-content-start flex-wrap align-items-center gap-2"
      >
        <li
          class="nav-item p-0"
          :class="page == 'your_rwd' ? 'active' : ''"
          @click="page = 'your_rwd'"
        >
          <a class="nav-link px-0 py-2 fs-6">Your Rewards</a>
        </li>

        <li
          class="nav-item p-0"
          :class="page == 'team_rwd' ? 'active' : ''"
          @click="page = 'team_rwd'"
        >
          <a class="nav-link px-0 py-2 fs-6">Teammates Rewards</a>
        </li>

        <li
          v-if="currentUser.user_role == 1"
          class="nav-item p-0"
          :class="page == 'manage_cat' ? 'active' : ''"
          @click="page = 'manage_cat'"
        >
          <a class="nav-link px-0 py-2 fs-6">Manage Category</a>
        </li>

        <li
          v-if="currentUser.user_role == 1"
          class="nav-item p-0"
          :class="page == 'manage_rwd' ? 'active' : ''"
          @click="page = 'manage_rwd'"
        >
          <a class="nav-link px-0 py-2 fs-6">Manage Rewards</a>
        </li>
        <!-- <li
          v-if="currentUser.user_role == 1"
          class="nav-item p-0"
          :class="page == 'rank_board' ? 'active' : ''"
          @click="page = 'rank_board'"
        >
          <a class="nav-link px-0 py-2 fs-6">Rank Board</a>
        </li> -->
      </ul>
    </div>
    <div class="bg-white p-2 pb-3 shadow-sm">
      <RewardComp :page="page"></RewardComp>
    </div>
  </div>
</template>

<script>
import myMixin from "@/mixins/Permission";
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
// import CustomPagination from "../../components/CustomPagination.vue";
import RewardComp from "../../components/RewardComp.vue";
// import 'vue2-datepicker/index.css';

export default {
  mixins: [myMixin],
  components: {
    Datepicker,
    Multiselect,
    RewardComp,
  },
  data() {
    return {
      siteLogReq: {
        site_name: "rewards",
      },
      page: "your_rwd",
      disabled: false,
      submitAction: "add",
      multiSelection: true,
      tempObj: null,
      users: [],
      awardObj: {
        user_id: [],
        month: moment().format("YYYY-MM-DD"),
        cat_id: null,
        notes: "",
      },
      modal_rewards: [],
      showAdminColumns: false,
      credit_debit_obj: {
        dropdown_options: ["Credit", "Debit"],
        selected_option: null,
        credit_debit_value: 1,
        show_input: false,
      },

      messages: {
        user_error: "",
        category_error: "",
        action_error: "",
      },
      filter_data: {
        users: [],
        selected_users: [],
        rewards: [],
        selected_rewards: [],
        from: moment().startOf("month").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        page: 1,
        perpage: 100,
        total_pages: 1,
        total_records: 0,
        sort: [],
      },
      summaryObj: {
        available: 0,
        earned: 0,
        opening: 0,
        total_points: 0,
      },
      permissions: localStorage.getItem("permissions"),
      action: null,
      rewards_columns: [
        {
          label: "Reward Code",
          field: "code",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
        },
        {
          label: "Reward Name",
          field: "name",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
        },
        {
          label: "Reward Points",
          field: "points",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
        },
        {
          label: "Status",
          field: this.fieldFn,
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
        },
        {
          label: "Action",
          field: "action",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
      ],
      your_rewards_columns: [
        {
          label: "Reward",
          field: "name",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Date",
          field: "date",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Points Earned",
          field: "total",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Notes",
          field: "notes",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Rewarded By",
          field: "created_name",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
      ],
      team_rewards_columns: [
        {
          label: "Username",
          field: "full_name",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Date",
          field: "date",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Category",
          field: "name",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Credit",
          field: "credit",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Debit",
          field: "debit",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Notes",
          field: "notes",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Points",
          field: "total",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Rewarded By",
          field: "created_name",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
      ],
      rewards_rows: null,
      your_rewards_rows: null,
      team_rewards_rows: null,
      rewardSummary: null,
      totalRecords: 0,
      rewardObj: {
        name: "",
        points: 0,
        code: "",
        is_active: true,
      },
      modal_title: "",
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),

    leftArrowCss() {
      if (this.filter_data.page == 1) {
        return "disabled text-secondary";
      }
      return "text-primary";
    },
    rightArrowCss() {
      if (this.filter_data.page == this.filter_data.total_pages) {
        return "disabled text-secondary";
      }
      return "text-primary";
    },
    selectedOption() {
      return this.credit_debit_obj.selected_option;
    },
  },
  mounted() {
    this.getUserPermissions();
    this.setCurrentPage("Rewards");
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  watch: {
    selectedOption: {
      handler(nv, ov) {
        // console.log("changed");
        if (nv != ov && nv != null) {
          this.credit_debit_obj.show_input = true;
          this.credit_debit_obj.credit_debit_value = 1;
          if (nv == "Credit" && this.multiSelection == false) {
            this.credit_debit_obj.credit_debit_value = this.tempObj.credit;
          }
          if (nv == "Debit" && this.multiSelection == false) {
            this.credit_debit_obj.credit_debit_value = this.tempObj.debit;
          }
        } else {
          // console.log("else");
          this.credit_debit_obj.show_input = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("common", ["setCurrentPage"]),

    updateParams(newProps) {
      this.filter_data = Object.assign({}, this.filter_data, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.filterHandler();
    },

    onPerPageChange(params) {
      this.updateParams({ perpage: params.currentPerPage });
      this.filter_data.page = 1;
      this.filterHandler();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.filterHandler();
    },
    summaryModalHandler() {
      this.getRewardsSummary();
      $("#rewardSummaryModal").modal("show");
    },
    getRewardsSummary() {
      let obj = {
        id: this.currentUser.id,
        start_date: moment(this.filter_data.from).format("YYYY-MM-DD"),
        end_date: moment(this.filter_data.to).format("YYYY-MM-DD"),
      };
      user.getRewardsSummary(obj).then((res) => {
        this.rewardSummary = res.data.data;
        // console.log(this.rewardSummary);
      });
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode < 48 || charCode > 57) &&
        (charCode !== 46 || this.rewardObj.points.indexOf(".") >= 0)
      ) {
        // 46 is for dot
        evt.preventDefault();
      }
      return true;
    },
    fieldFn(value) {
      return value.is_active ? "Active" : "In Active";
    },
    filterHandler() {
      let action = null;
      this.page == "your_rwd" ? (action = "your") : (action = "team");

      this.filter_data.from == null ? (this.filter_data.from = moment()) : "";

      let obj = {
        id: this.currentUser.id,
        cat_ids: this.filter_data.selected_rewards.map((item) => item.id),
        user_ids: this.filter_data.selected_users.map((item) => item.id),
        month: [
          moment(this.filter_data.from).startOf("month").format("YYYY-MM-DD"),
          moment(this.filter_data.to).format("YYYY-MM-DD"),
        ],
        rewards: action,
        page: this.filter_data.page,
        perpage: this.filter_data.perpage,
        sort: this.filter_data.sort,
      };

      user.getUserRewards(obj).then((result) => {
        this.filter_data.total_records = result.data.data.total;
        if (action == "your") {
          this.your_rewards_rows = result.data.data.data;
          // console.log(this.your_rewards_rows, this.totalRecords);
          this.summaryObj.available = result.data.data.available;
          this.summaryObj.opening = result.data.data.opening;
          this.summaryObj.earned = result.data.data.earned;
        } else {
          if (
            !(
              result.data.data instanceof Object &&
              result.data.data instanceof Array
            )
          ) {
            this.team_rewards_rows = result.data.data.data;
            this.summaryObj.total_points = result.data.data.total_points;
          } else {
            this.team_rewards_rows = null;
          }
        }
        return;
      });
    },
    dateHandler(event) {
      this.filter_data.from = moment(event).endOf("month").format("YYYY-MM-DD");
    },
    closeModalHandler() {
      // To reset award reward modal fields
      this.rewardObj = {
        name: "",
        points: 0,
        code: "",
        is_active: false,
      };
      this.awardObj = {
        user_id: [],
        month: moment().format("YYYY-MM-DD"),
        cat_id: null,
        notes: "",
      };
      // To reset error messages
      (this.messages = {
        user_error: "",
        category_error: "",
        action_error: "",
      }),
        // To reset the points feild value and visibility in modal
        (this.credit_debit_obj = {
          dropdown_options: ["Credit", "Debit"],
          selected_option: null,
          credit_debit_value: 1,
          show_input: false,
        });
    },
    formHandler() {
      // To check for award reward modal fields validation and set error messages
      let check = true;
      if (this.awardObj.user_id.length == 0) {
        this.messages.user_error = "Please select user";
        check = false;
      } else {
        this.messages.user_error = "";
        check = true;
      }
      if (this.awardObj.cat_id == null) {
        this.messages.category_error = "Please select category";
        check = false;
      } else {
        this.messages.category_error = "";
        check = true;
      }
      // console.log("================ ",this.credit_debit_obj);
      if (
        this.credit_debit_obj.selected_option == null &&
        this.multiSelection
      ) {
        this.messages.action_error = "Please select action";
        check = false;
      } else {
        this.messages.action_error = "";
        check = true;
      }

      if (check) {
        // To set credit and debit values in object if present
        this.credit_debit_obj.selected_option == "Credit"
          ? (this.awardObj.credit = this.credit_debit_obj.credit_debit_value)
          : this.credit_debit_obj.selected_option == "Debit"
          ? (this.awardObj.debit = this.credit_debit_obj.credit_debit_value)
          : "";
        // To set month if blank value passed
        this.awardObj.month == "" ? (this.awarObj.month = new Date()) : "";

        // To call api for create or update
        if (this.multiSelection) {
          user
            .setUserRewards(this.awardObj)
            .then((result) => {
              Swal.fire("Success", result.data.message, "success");
              this.filterHandler();
            })
            .catch((err) => {
              Swal.fire("Error", result.data.message, "error");
            });
        } else {
          user
            .updateUserRewards(this.awardObj)
            .then((result) => {
              Swal.fire("Success", result.data.message, "success");
              this.filterHandler();
            })
            .catch((err) => {
              Swal.fire("Error", result.data.message, "error");
            });
        }
        $("#rewardModal").modal("hide");
      }
    },
    rewardModalHandler(item) {
      // console.log(item, this.rewards_rows);
      if (item) {
        this.tempObj = JSON.parse(JSON.stringify(item));
        this.multiSelection = false;

        // The below line of code will make the watcher to trigger form where we will set the values
        this.credit_debit_obj.selected_option = "Credit";

        this.awardObj.id = item.reward_id;
        this.awardObj.notes = item.notes;
        this.awardObj.month = item.date;
        this.awardObj.cat_id = this.rewards_rows.find(
          (i) => i.id == item.cat_id
        );
        this.awardObj.user_id = [
          this.users[0].values.find((i) => i.id == item.user_id),
        ];
      } else {
        this.multiSelection = true;
      }
      $("#rewardModal").modal("show");
    },
    // For credit/debit modal
    addRewardHandler() {
      $("#addRewardModal").modal("show");
    },
    // For modification of username
    custom_label(name) {
      // console.log(name.first_name,name.middle_name,name.last_name);
      if (name.last_name != null && name.middle_name != null) {
        return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      } else if (name.last_name == null) {
        return `${name.first_name} ${name.middle_name}`;
      } else if (name.middle_name == null) {
        return `${name.first_name} ${name.last_name}`;
      }
    },
    // To fetch the users for award assignment
    awardUserHandler() {
      user.getUsers().then((result) => {
        // for award reward modal
        this.users = [
          {
            label: "Select All",
            values: result.data.data.filter(
              (item) => [1, 2].includes(item.user_type) && !item.is_deleted
            ),
          },
        ];

        // for filters
        this.filter_data.users = [
          {
            label: "Select All",
            values: result.data.data.filter((item) => {
              if (this.page == "team_rwd") {
                return (
                  [1, 2].includes(item.user_type) &&
                  item.id != this.currentUser.id
                );
              } else {
                return [1, 2].includes(item.user_type);
              }
            }),
          },
        ];
        // console.log("user before-------- ",this.filter_data.users);
        // if(this.page=="team_rwd"){
        //   this.filter_data.users[0].values=this.filter_data.users[0].values.filter(item=>item.id!=this.currentUser.id);
        //   console.log("user if-------- ",this.filter_data.users);
        // }
      });
    },
    // To handle nav tab changes
    // optionChanger(name) {
    //   if (this.page == name) return;
    //   this.rewards_rows =
    //     this.your_rewards_rows =
    //     this.team_rewards_rows =
    //       null;
    //   this.page = name;
    //   this.disabled = false;
    //   this.filter_data = Object.assign(this.filter_data, {
    //     id: this.currentUser.id,
    //     users: [],
    //     selected_users: [],
    //     rewards: [],
    //     selected_rewards: [],
    //     from: moment().startOf("month").toDate(),
    //     to: moment().endOf("month").toDate(),
    //     page: 1,
    //     perpage: 100,
    //     total_pages: null,
    //   });

    //   if (this.page == "manage_cat") {
    //     this.rewards_rows = null;
    //     console.log("manage cat");
    //     this.getRewardsCategories();
    //   } else if (this.page == "your_rwd") {
    //     // this.awardUserHandler();
    //     if (this.currentUser.user_role == 1) {
    //       this.getRewardsCategories();
    //     }
    //     this.filterHandler("your");
    //     [...document.querySelectorAll('[data-bs-toggle="tooltip"]')].forEach(
    //       (el) => new Tooltip(el)
    //     );
    //   } else if (this.page == "team_rwd" || this.page == "manage_rwd") {
    //     this.awardUserHandler();
    //     this.filterHandler();
    //     if (this.currentUser.user_role == 1) {
    //       let actionObj = {
    //         label: "Action",
    //         field: "user_id",
    //         thClass: "custom-th",
    //         tdClass: "custom-td",
    //         sortable: false,
    //       };
    //       this.team_rewards_columns.splice(6, 1, actionObj);
    //       this.getRewardsCategories();
    //       this.showAdminColumns = true;
    //       return;
    //     }
    //     this.showAdminColumns = false;
    //   }
    // },
    // To get list of reward category
    getRewardsCategories() {
      user.getRewardsCategories().then((result) => {
        // this.rewards = result.data.data;
        this.rewards_rows = result.data.data;
        this.filter_data.rewards = result.data.data.filter(
          (item) => item.is_active
        );
        this.modal_rewards = result.data.data.filter((item) => item.is_active);
      });
    },
    // To call the add or update api for reward category
    submitHandler() {
      // console.log(dataObj);
      if (!Object.keys(this.rewardObj).includes("id")) {
        user.addRewards(this.rewardObj).then((result) => {
          if (result.data.code == 200) {
            Swal.fire("Success", result.data.message, "success");
            this.getRewardsCategories();
          }
          if (result.data.code == 400) {
            Swal.fire("Error", result.data.message, "error");
          }
          $("#addRewardModal").modal("hide");
        });
      } else {
        user.updateRewards(this.rewardObj).then((result) => {
          if (result.data.code == 200) {
            Swal.fire("Success", result.data.message, "success");
            this.getRewardsCategories();
          }
          if (result.data.code == 400) {
            this.rewardObj.name = this.tempObj.name;
            this.rewardObj.points = this.tempObj.points;
            Swal.fire("Error", result.data.message, "error");
          }
          $("#addRewardModal").modal("hide");
        });
        // }
        // else{
        // this.disabled = false;
        // this.awardObj=this.tempObj;
        // Swal.fire("Error","Nothing to update","error");
        // }
      }
    },
    // To delete the user rewards
    deleteUserRewards(item) {
      Swal.fire({
        title: "Delete",
        text: "Are you sure you want to delete?",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#32a852",
        cancelButtonColor: "#f00",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((res) => {
        if (res.value) {
          user
            .deleteUserRewards(item.user_id, item.reward_id)
            .then((result) => {
              if (result.data.code == 200) {
                Swal.fire("Success", result.data.message, "success");
                this.filterHandler();
              }
              if (result.data.code == 400) {
                Swal.fire("Error", result.data.message, "error");
              }
            })
            .catch((err) => {
              Swal.fire("Error", "Something Went wrong", "error");
            });
        }
      });
    },
    // To delete the reward category
    deleteRewards(id) {
      // console.log("delete ",id);
      Swal.fire({
        title: "Delete",
        text: "Are you sure you want to delete?",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#32a852",
        cancelButtonColor: "#f00",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((res) => {
        if (res.value) {
          user
            .deleteRewards(id)
            .then((result) => {
              user;
              this.getRewardsCategories();
              if (result.data.code == 200) {
                Swal.fire("Success", result.data.message, "success");
                this.getRewardsCategories();
              }
              if (result.data.code == 400) {
                Swal.fire("Error", result.data.message, "error");
              }
            })
            .catch((err) => {
              Swal.fire("Error", "Something Went wrong", "error");
            });
        }
      });
    },
    // To modify the list for add and update functionality
    rewardHandler(action, item) {
      if (action == "update") {
        this.modal_title = "Update Reward Category";
        this.rewardObj.name = item.name;
        this.rewardObj.code = item.code;
        this.rewardObj.points = item.points;
        this.rewardObj.is_active = item.is_active;
        this.rewardObj.id = item.id;
      } else {
        delete this.rewardObj.id;
        this.modal_title = "Add Reward Category";
      }
      $("#addRewardModal").modal("show");
    },
  },
};
</script>

<style scoped>
#reward_table tr td {
  font-size: 18px;
}
.active a {
  color: #583d72;
  border-bottom: 2px solid #583d72;
}
.nav-link {
  font-size: 16px !important;
  padding-bottom: 8px !important;
  border-bottom: 2px solid transparent;
  color: #79869f;
  cursor: pointer;
}

.tab {
  cursor: pointer;
  border-bottom: 2px solid transparent;
  border-width: 0px;
}

.tab:hover {
  /* box-shadow: 0px 0px 4px gainsboro; */
  border-bottom: 2px solid dodgerblue;
  border-width: 100%;
  /* transition: 0.2s ease-in-out; */
}

input[type="number"]:disabled {
  background: white;
}

::v-deep .vdp-datepicker__calendar-button .input-group-text {
  height: 100%;
  background-color: whitesmoke;
  border-top-right-radius: 0;
  border-right: 0px;
  border-bottom-right-radius: 0;
}
::v-deep .vdp-datepicker__clear-button .input-group-text {
  height: 100%;
  background-color: white;
  border-top-left-radius: 0;
  border-left: 0px;
  border-bottom-left-radius: 0;
  border-color: #f0f0f0;
}
</style>
